@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto+Slab:wght@600&display=swap');
@import url('https: //fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto+Slab:wght@500&display=swap');
@import url('https: //fonts.googleapis.com/css2?family=Poppins:wght@300&family=Roboto+Slab:wght@300&display=swap');

.no-scroll {
    overflow: hidden !important;
}

* {
    margin: 0;
    padding: 0;
}

.disable-scrolling {
    position: relative;
}

.custom-input::-webkit-inner-spin-button,
.custom-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.custom-input {
    -moz-appearance: textfield;
}

.bg-gradient-curve {
    background-image: none;
}

.bg-gradient-curve2 {
    background-image: none;
    background-image: linear-gradient(-270deg, #1CB5E0, #1CB5E0, #1CB5E0, #1CB5E0, #1CB5E0,
            #1CB5E0, #F7F5F8, #F7F5F8);
}

@media screen and (min-width: 1030px) {
    .bg-gradient-curve {
        background-image: radial-gradient(ellipse at top left, #1CB5E0, #1CB5E0, #F7F5F8, #F7F5F8, #F7F5F8, #F7F5F8);
        background-repeat: no-repeat;
    }
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
}

:root {
    --primary: white;
    --white: #ffffff;
    --bg: #f5f5f5;
}

slider {
    scroll-behavior: smooth;
}

@media (min-width: 1440px) {
    slider {
        zoom: 1.2;
    }
}

@media (min-width: 2560px) {
    slider {
        zoom: 1.5;
    }
}

@media (min-width: 3860px) {
    slider {
        zoom: 2;
    }
}

body {
    font-size: 1.6rem;
    background: var(--bg);
}

.container {
    max-width: 124rem;
    padding: 4rem 1rem;
    margin: 0 auto;
}

.heading {
    padding: 1rem 0;
    font-size: 3.5rem;
    text-align: center;
}

.swiper_container {
    height: 40rem;
    padding: 2rem 0;
    position: relative;
}

.swiper-slide {
    height: auto;
}

.swiper-slide {
    width: 50%;
}

.swiper-slide img {
    width: auto;
    height: auto;
    object-fit: cover;
}

#slider-img{
    width: 100% !important;
    height: auto;
    object-fit: cover;
}
#swiper-item-id{
    padding: 0px 15px;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
}

.slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateY(40%);
    transform: translateX(-49%);
    color: white;
}
#swiper-item-container{
    padding-left:40px;

}




@media (min-width: 1250px) {
    .slider-controler .swiper-button-next {
        left: 55% !important;
        transform: translateY(1%);
    }

    .slider-controler .swiper-button-prev {
        left: 38% !important;
    }

    .swiper_container {
        height: 40rem;
    }
}



.slider-controler .slider-arrow {
    width: 4.5rem;
    height: 4.5rem;
    left: 38%;
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow {
    color: black;
}

.slider-controler .slider-arrow::after {
    content: '';
}

.swiper-pagination {
    bottom: -100px;
    padding: -60rem 0;
}

.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
}

.swiper-pagination2 {
    bottom: -100px;
    padding: -60rem 0;
}

.swiper-pagination2 .swiper-pagination2-bullet2 {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination2 .swiper-pagination2-bullet-active {
    background: black !important;
}



.agencies {
    background-position: top 15px right 0px;
    background-position-x: 60%;
    background-position-y: 15%;
    background-repeat: no-repeat;
}


@media screen and (min-width: 1023px) {
    .agencies {
        background-size: 100% 100%;
    }
}

.card {
    animation-name: rotate;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.card:hover {
    animation: none;
}

@keyframes rotate {

    from,
    to {
        animation-timing-function: ease-in;
        transform: rotateY(-35deg);
    }

    25%,
    75% {
        animation-timing-function: ease-out;
        transform: rotateY(0deg);
    }

    50% {
        animation-timing-function: ease-in;
        transform: rotateY(35deg);
    }
}

.topBtn {
    animation: gototop .8s linear infinite alternate-reverse;
}

@keyframes gototop {
    0% {
        transform: translateY(-0.3rem);
    }

    100% {
        transform: translateY(0.3rem);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.ocr {
    animation-name: slowmo;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.know {
    animation: goto .8s linear infinite;
}

#know {
    animation: goto 1.5s linear infinite;
}
@keyframes goto {
    0% {
        transform: translateX(-0.3rem);
    }

    100% {
        transform: translateX(0.3rem);
    }
}

@keyframes slowmo {

    from,
    to {
        animation-timing-function: ease-in;
        transform: rotateZ(-2deg);
    }

    25%,
    75% {
        animation-timing-function: ease-out;
        transform: rotateZ(0deg);
    }

    50% {
        animation-timing-function: ease-in;
        transform: rotateZ(2deg);
    }
}



.anim-btn__button {
    transition: color 300ms ease, transform 300ms ease;
}

.anim-btn__button:active {
    transform: scale(0.8);
}

.active-service {
    color: #fff;
    background-image: -webkit-linear-gradient(45deg, #000046, #1CB5E0);
}

@keyframes pop-up {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}

@keyframes pop-out {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

.logo {
    @apply animate-pop-up;
    @apply animate-pop-out;
}

.animate-pop-up {
    animation: pop-up 3s ease infinite;
}

.animate-pop-out {
    animation: pop-out 3s ease infinite;
}


.scrollbar-hide {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

.scrollbar-hide::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.scrollbar-hide::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.scrollbar-hide::-webkit-scrollbar-track {
    background-color: transparent;
}


.about {
    background: linear-gradient(225deg, #FBFBFB 0%, rgba(186, 230, 242, 0.48) 100%);
}

.testimonial {
    /* background: rgba(250, 250, 250, 0.95); */
}
.input-phone-number input:focus{
    outline: none !important;
    border:none !important;
    box-shadow: none !important;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

  .mega-menu {
    opacity:0;
    visibility:hidden;
    z-index: -900;
    left: 0;
    position: absolute;
    text-align: left;
    width: 100%;
    transition: all 0.15s linear 0s;
  }

  /* #hoverable Class Styles */
  .hoverable {
    position: static;
  }

  .hoverable > a:after {
    content: "\25BC";
    font-size: 10px;
    font-weight: lighter;
    padding-left: 6px;
    position: relative;
    top: -1px;
  }

  .hoverable:hover .mega-menu {
    opacity:1;
    visibility:visible;
    z-index: 900;
  }


  /* #toggle Class Styles */

  .toggleable > label:after {
    content: "\25BC";
    font-size: 10px;
    font-weight: lighter;
    padding-left: 6px;
    position: relative;
    top: -1px;
  }

  .toggle-input {
    display: none;
  }
  .toggle-input:not(checked) ~ .mega-menu {
    opacity:0;
    visibility:hidden;
    z-index: -900;
  }

  .toggle-input:checked ~ .mega-menu {
    opacity:1;
    visibility:visible;
    z-index: 900;
  }

  .toggle-input:checked + label {
    color: white;
    background: rgb(3, 102, 114); /*@apply bg-teal-700 */
  }

  .toggle-input:checked ~ label:after {
    content: "\25B2";
    font-size: 10px;
    font-weight: lighter;
    padding-left: 6px;
    position: relative;
    top: -1px;
  }
.new-transit-css{
    transition: all 0.15s linear 0s
}
@keyframes slideDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
.sticky-from-top {
    position: fixed !important;
    /* box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1); */
    /* padding: 8px 0; */
    /* backdrop-filter: blur(10px); */
    animation: slideDown 0.35s ease-out;
    /* top: 0; */
    /* top: 0; */
    /* transition: top 0.3s ease-in-out; */
    /* transition: opacity 0.8s ease-in-out, transform 0.8s ease-in-out; */
  }

  /* Service Dropdown */

  .service-menu {
    opacity:0;
    visibility:hidden;
    z-index: -900;
    position: absolute;
    text-align: center;
    width: 200px;
    transition: all 0.15s linear 0s;
    margin-left: -60px;
  }



  /* #hoverable Class Styles */
  .hoverable {
    position: static;
  }

  .hoverable > a:after {
    content: "\25BC";
    font-size: 10px;
    font-weight: lighter;
    /* padding-left: 6px; */
    position: relative;
    top: -1px;
  }

  .hoverable:hover .service-menu {
    opacity:1;
    visibility:visible;
    z-index: 900;
  }


  /* #toggle Class Styles */

  .toggleable > label:after {
    content: "\25BC";
    font-size: 10px;
    font-weight: lighter;
    /* padding-left: 6px; */
    position: relative;
    top: -1px;
  }

  .toggle-input {
    display: none;
  }
  .toggle-input:not(checked) ~ .service-menu {
    opacity:0;
    visibility:hidden;
    z-index: -900;
  }

  .toggle-input:checked ~ .service-menu {
    opacity:1;
    visibility:visible;
    z-index: 900;
  }

  .no-scroll {
    overflow: hidden;
  }